import { Box, Divider, Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { Layout, Seo } from "../../components";
import "../../styles/screen.scss";

const seoBundle = {
  title: "Steel Construction in the Northwest",
  subtitle: "JMF Steel Erectors",
  description: "Steel Construction is on the rise in the Pacific Northwest",
  slug: "/articles/northwest",
};

const Blog1 = ({ location }) => {
  return (
    <Layout title={"Steel Construction in the Northwest"} location={location}>
      <Seo seoBundle={seoBundle} />
      <article>
        <Flex
          maxW='1450px'
          h='100%'
          // header takes up 40/50 pixels
          pt={["150px", null, "150px"]}
          px={["20px", null, "100px"]}
          pb={["60px", null, "100px"]}
          align='center'
          justify='center'
          flexDirection='column'
        >
          <Heading
            as='h1'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["3em", "4em"]}
            fontFamily='heading'
            lineHeight={["100%", null, null, "120%"]}
            textAlign='center'
          >
            Steel Construction in the Northwest
          </Heading>
          <Heading
            as='h2'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            mt='0px'
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            A Booming Industry in the Northwest US
          </Heading>
          <Flex flexDir='row'>
            <Text my='30px' fontWeight={600} textAlign='center'>
              “The steel erection construction industry has become a vital
              component of the economic growth in the Northwest.”
            </Text>
          </Flex>
          <HStack display='flex' align='center'>
            <Box
              w='100%'
              display='flex'
              h={["60px"]}
              w={["60px"]}
              justifyContent='center'
              mr='10px'
            >
              <StaticImage
                src='../images/jake.jpg'
                alt='Jake Fillmore, owner and CEO'
                placeholder='blurred'
                layout='constrained'
                style={{ borderRadius: "50%" }}
              />
            </Box>
            <Flex flexDir='column'>
              <Text fontFamily='heading' mb='0' fontWeight={600}>
                Jake Fillmore
              </Text>
              <Text as='span' mt='0' pt='0' fontSize='12px'>
                CEO, Owner & Operator <br />
                <Text fontFamily='heading' fontWeight={700}>
                  JMF Steel Construction
                </Text>
              </Text>
            </Flex>
          </HStack>
          <Flex
            w='100%'
            my={["30px"]}
            h={["200px", null, "200px", "400px", "600px"]}
            // w={["250px", "350px", "150px", "250px", "350px"]}
            justifyContent='center'
          >
            <StaticImage
              src='../../images/feusa.jpg'
              alt='roof top garden in large city'
              placeholder='blurred'
              // height={200}
              // width={200}
              // height={200}
            />
          </Flex>
          <Text
            mt='20px'
            mb='7'
            className='cap'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Steel erection construction has been experiencing a significant boom
            in the Northwest region of the United States. This industry, which
            involves the assembling of steel structures, has become an integral
            part of the regional economy. The Northwest's diverse landscape,
            growing population, and thriving industries have created a high
            demand for steel erection construction. This article will provide an
            overview of the industry in the Northwest, highlighting the factors
            driving growth and the challenges faced by construction companies.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            The steel erection construction industry has become a vital
            component of the economic growth in the Northwest. Home to key
            cities like Seattle, Portland, and Boise, the region boasts a
            diverse economy driven by technology, manufacturing, and
            agriculture. These sectors require robust infrastructure, which has
            led to an increased demand for steel erection construction. Steel,
            known for its durability and strength, has become the material of
            choice for large-scale projects.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Factors Driving Growth
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Technology and Green Energy: The Northwest has been attracting major
            technology companies such as Amazon, Microsoft, and Intel. With the
            growth of the tech sector comes the need for data centers, office
            buildings, and other facilities. Additionally, the region is a
            leader in green energy development, with numerous wind farms and
            solar power installations. These projects often require steel
            structures to support turbines, solar panels, and other equipment.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Population Growth: The Northwest region is experiencing a steady
            population increase, leading to a demand for residential and
            commercial construction. Steel erection is instrumental in providing
            shops, warehouses, and storage facilities that accommodate the
            growing population while maximizing land usage.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Agriculture Projects: The region has always investing heavily in
            agricultural development. Steel erection construction plays a vital
            role in the creation of needed structures. Steel's strength and
            unique properties make it very suitable for agriculture.
          </Text>
          <Heading
            as='h2'
            mt='40px'
            color='dkGrey'
            mb={["20px", null, null, "10px"]}
            fontWeight={800}
            fontSize={["1.5em", "2em"]}
            fontFamily='body'
            lineHeight={["120%"]}
            textAlign='center'
          >
            Challenges in the Industry
          </Heading>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Despite the growth, the steel erection construction industry in the
            Northwest faces several challenges. Key among them are:
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Skilled Labor Shortage: The industry is grappling with a shortage of
            skilled laborers, particularly in the areas of welding and steel
            erection. This shortage has led to project delays and increased
            costs as companies compete for the limited workforce. Training
            programs and apprenticeships are being developed to address this
            issue.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            Volatile Steel Prices: The price of steel is subject to global
            market fluctuations, which can significantly impact the cost of
            steel erection construction. Companies must constantly monitor and
            adjust to these changes to remain competitive.
          </Text>
          <Text
            mt='20px'
            mb='7'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
            className='indent'
          >
            The steel erection construction industry is a critical element of
            the Northwest's economic growth. Driven by technology, population
            growth, and agricultural development, the demand for steel
            structures continues to rise. While the industry faces challenges
            such as skilled labor shortages and volatile steel prices, the
            Northwest's steel erection construction sector remains poised for
            success.
          </Text>
          <Divider maxW='600px' my='40px' orientation='horizontal' />
          <Heading as='h3' textAlign='center'>
            JMF Construction
          </Heading>
          <Heading as='h4' fontFamily='body' fontSize='16px'>
            A Boise, Idaho Company
          </Heading>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            We’ve done projects all over the Treasure Valley and beyond for
            businesses such as Simplot Western Stockmen’s, CSDI, Champion
            Produce in Parma, and Farm Equipment USA in Fruitland and Jerome.
            Have a small project? You’ve come to the right place. We build
            smaller projects regularly, not just the large scale ones.
          </Text>
          <Text
            mt='20px'
            mb='7'
            textAlign='center'
            fontSize={20}
            maxW='800px'
            lineHeight='120%'
          >
            Here at JMF, we make it our business to get to know you and your
            unique situation. Call us today!
          </Text>
          <Text
            mb='40px'
            mt='20px'
            fontSize={24}
            textAlign='center'
            maxW='800px'
            lineHeight='120%'
            fontWeight='bold'
            color='dkGrey'
          >
            Call us at <a href='tel:2088801062'>(208) 880-1062</a>
          </Text>
        </Flex>
      </article>
    </Layout>
  );
};

export default Blog1;
